$primary: #00E5C9;
$secondary: #FDB11A;
$dark: #212234;

$body-bg: lighten($dark, 5);
$body-color: #fff;

$accordion-color: #fff;
$pagination-bg: $dark;
$pagination-active-bg: darken($primary, 8);
$pagination-disabled-color: darken($primary, 8);
$pagination-disabled-bg: darken($dark, 2);

$form-check-input-width: 1.8em;
$form-check-input-border: 1px solid #ced4da;

@import "~bootstrap/scss/bootstrap";
@import "accordion-list";
@import "navigation";
@import "page-pagination";