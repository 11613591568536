.accordion {
  position: relative;
}

.accordion.loading::after {
  content: "";
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $dark;
  opacity: 0.7;

  -webkit-animation-name: fadeLoadIn;
  -webkit-animation-duration: 1s;
  animation-name: fadeLoadIn;
  animation-duration: 1s;
}

@-webkit-keyframes fadeLoadIn {
  0% { opacity: 0; }
  100% { opacity: 0.7; }
}

@keyframes fadeLoadIn {
  0% { opacity: 0; }
  100% { opacity: 0.7; }
}